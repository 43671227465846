@import "~react-image-gallery/styles/css/image-gallery.css";

.App {
  width: 100%;
  height: 100vh;
}

.image-gallery-slide .image-gallery-image {
  width: 40vw;
  height: calc(100vh - 7rem);
  border-radius: 1rem;
  object-fit: cover;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c4c4c43d;
}

.PhoneInputInput {
  border: none;
  outline: none;
}

@media only screen and (max-width: 769px) {
  ::-webkit-scrollbar {
    width: 3px;
  }
}
